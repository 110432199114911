/* eslint global-require: "off" */
export interface NfishFeature {
  name: string;
  img: string;
}
export const nFishFeatures = [
  {
    name: 'fishToken',
    img: require('../../assets/images/economics/nfish/features/nfish.png'),
  },
  {
    name: 'stakeForMining',
    img: require('../../assets/images/economics/nfish/features/mining.png'),
  },
  {
    name: 'gameEarn',
    img: require('../../assets/images/economics/nfish/features/game-earn.png'),
  },
  {
    name: 'nftTrade',
    img: require('../../assets/images/economics/nfish/features/trade.png'),
  },
];

export interface TokenProportion {
  name: string;
  iconColor: string;
}
export const tokenProportion = [
  {
    name: 'teDesc1',
    iconColor: '#6e84f1',
  },
  {
    name: 'teDesc2',
    iconColor: '#fed776',
  },
  {
    name: 'teDesc3',
    iconColor: '#a1b5fc',
  },
  {
    name: 'teDesc4',
    iconColor: '#2476e1',
  },
  {
    name: 'teDesc5',
    iconColor: '#6e84f1',
  },
];

export const fishingProportion = [
  {
    name: 'feDesc1',
    iconColor: '#6b81e8',
  },
  {
    name: 'feDesc2',
    iconColor: '#f4cf73',
  },
  {
    name: 'feDesc3',
    iconColor: '#9daff2',
  },
  {
    name: 'feDesc4',
    iconColor: '#a5c6ef',
  },
];
