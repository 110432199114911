export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NFISH Tokenomics"])},
        "nFishDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NFISH token is the Neco Fishing game token with a fixed supply. As the game continues to grow, NFISH will be burned accordingly to increase its value."])},
        "maxSupply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max supply"])},
        "circulation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Circulation"])},
        "burned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burned"])},
        "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy"])},
        "feature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Features"])},
        "fishToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neco Fishing Game Token"])},
        "stakeForMining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enjoy Staking & Farming"])},
        "gameEarn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play To Earn NFISH"])},
        "nftTrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NFT Trading"])},
        "tokenEconomics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokenomics"])},
        "teDesc1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1% will be used for fair launch and for adding liquidity in PancakeSwap (pair with BUSD)."])},
        "teDesc2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1% will be used for airdrops to users holding/staking NECOs."])},
        "teDesc3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15% will be used for game development and will be distributed to team members and marketers."])},
        "teDesc4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["25% will be used for staking & farming. (2 pools to stake NECO&BNB or NFISH&BUSD)."])},
        "teDesc5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["58% will be used for Neco Fishing game rewards to be earned through P2E. Play more to earn more."])},
        "finishingEconomics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neco Fishing Game Economic Model"])},
        "feDesc1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30% of game earnings will be distributed to NECO token holders. All NECO token holders are shareholders of NECO FUN."])},
        "feDesc2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30% of NFISHs earned from the game will be burned to reduce token circulation and increase NFISH token value."])},
        "feDesc3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30% of game earnings will be used to buyback NECOs. Meanwhile, NECO token circulation will be reduced to increase NECO token value."])},
        "feDesc4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10% of game earnings will be used for project development. More funds will be invested to improve Neco Fishing game."])}
      },
      "zh": {
        "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NFISH代币经济学"])},
        "nFishDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NFISH代币为Neco Fishing游戏流通代币，总量固定，并随着游戏不断燃烧销毁，价值不断上升。"])},
        "maxSupply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最大供应量"])},
        "circulation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["流通量"])},
        "burned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已燃烧"])},
        "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["购买"])},
        "feature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["功能"])},
        "fishToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neco Fishing游戏币"])},
        "stakeForMining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["享受质押挖矿"])},
        "gameEarn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["游戏收益代币"])},
        "nftTrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NFT交易代币"])},
        "tokenEconomics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NFISH代币经济学"])},
        "teDesc1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1% 将会用于公平发射，添加PancakeSwap的流动池（配对BUSD）"])},
        "teDesc2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1% 将会用于空投，发给NECO的持有者，质押者。"])},
        "teDesc3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15% 将会用户游戏的开发，发送给团队成员和市场人员。"])},
        "teDesc4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["25% 将会通过质押挖矿分发（2个挖矿池子NECO&BNB，NFISH&BUSD）"])},
        "teDesc5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["58% 将会通过Play to Earn的方式在Neco Fishing游戏中进行获得，玩的越多获取的越多。"])},
        "finishingEconomics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neco Fishing游戏经济模型"])},
        "feDesc1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30% 游戏收益将会分发给NECO代币的持有者，每个NECO代币的持有者都是NECO FUN的股东。"])},
        "feDesc2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30% 游戏中收取的NFISH代币将会进行销毁，减少市场的流通量，增加NFISH代币的价值。"])},
        "feDesc3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30% 游戏收益用于回购NECO代币，同时减少NECO代币的市场流通量，不断增加NECO代币的价值。"])},
        "feDesc4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10% 游戏收益作为项目的开发资金，我们将投入更多的资金将Neco Fishing游戏做的更好。"])}
      }
    }
  })
}
